/*  Address information
    Mailing address information
*/

import * as React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "../../components/ui/LoadingButton";
import useLabels from "../../labels/useLabels";
import { useFormik } from "formik";
import * as validatorYup from "yup";
import { useMutation } from "@tanstack/react-query";
import HttpService from "../../services/HttpService";
import OnboardingEmployerAttributeService from "../../services/config/OnboardingEmployerAttributeService";

export default function StepAddress({ onSave, email, token, bagContent, currentStep, employerId }) {
  const ADDRESS_STEP = 4;  //its used to know if the currentStep is the address or mailing address
  const MAILING_ADDRESS_STEP = 5;
  const thisStep = currentStep;
  const [error, setError] = React.useState("");
  const { getLabel } = useLabels();
  const onboardingEmployerAttributes = OnboardingEmployerAttributeService(employerId, "PAYCARD_USE_DEFAULT_ADDRESS").attribute;

  const notOnlyWhitespace = validatorYup.string().test(
    'notOnlyWhitespace',
    'Cannot be just spaces',
    value => (value ? !value.match(/^ *$/) : true),
  );

  const validationSchema = validatorYup.object({
    address1: validatorYup.string().trim().required("Required field"),
    address2: validatorYup.string(),
    city: validatorYup.string().trim().required("Required field"),
    state: validatorYup
      .string()
      .oneOf(
        ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
        "Invalid state"
      )
      .required("Required field"),
    zip: validatorYup.string()
    .matches(
      /(^\d{5}$)|(^\d{5}-\d{4}$)/,
      "Invalid ZIP code"
    ).required("Required field"),
  });

  const validationSchemaMailing = validatorYup.object({
    mailingAddress1: validatorYup.string().required("Required field"),
    mailingAddress2: validatorYup.string(),
    mailingCity: validatorYup.string().required("Required field"),
    mailingState: validatorYup
      .string()
      .matches(
        /^[A-Z]{2}$/,
        "Invalid state"
      )
      .required("Required field"),
    mailingZip: validatorYup.string().required("Required field"),
  });
  const objInitiaValues = {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  };
  const objInitiaValuesMailing = {
    mailingAddress1: "",
    mailingAddress2: "",
    mailingCity: "",
    mailingState: "",
    mailingZip: "",
  };
  const formik = useFormik({
    initialValues: objInitiaValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const transformedValues = {
        email: email,
        token: token,
        step: (Number(thisStep) + 1).toString(),
        bagContent: { ...values },
      };
      processStep.mutate({ ...transformedValues });
    },
  });
  const formikMailing = useFormik({
    initialValues: objInitiaValuesMailing,
    validationSchema: validationSchemaMailing,
    onSubmit: (values) => {
      const transformedValues = {
        email: email,
        token: token,
        step: (Number(thisStep) + 1).toString(),
        bagContent: {
          ...values,
        },
      };
      setError("");
      processStep.mutate({ ...transformedValues });
    },
  });
  const processStep = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/saveStep`,
        data,
        { avoidBearer: true }
      );
    },
    {
      onSuccess: (values) => {
        onSave();
      },
      onError: (values) => {
        setError(values.response.data);
      },
    }
  );
  React.useEffect(() => {
    if (bagContent !== null) {
      if (Number(thisStep) === Number(ADDRESS_STEP)) {
        bagContent?.address1
          ? formik.setFieldValue("address1", bagContent?.address1)
          : formik.setFieldValue("address1", "");
        bagContent?.address2
          ? formik.setFieldValue("address2", bagContent?.address2)
          : formik.setFieldValue("address2", "");
        bagContent?.city
          ? formik.setFieldValue("city", bagContent?.city)
          : formik.setFieldValue("city", "");
        bagContent?.state
          ? formik.setFieldValue("state", bagContent?.state)
          : formik.setFieldValue("state", "");
        bagContent?.zip
          ? formik.setFieldValue("zip", bagContent?.zip)
          : formik.setFieldValue("zip", "");
      } else {
        bagContent?.mailingAddress1
          ? formikMailing.setFieldValue(
              "mailingAddress1",
              bagContent?.mailingAddress1
            )
          : formikMailing.setFieldValue("mailingAddress1", "");
        bagContent?.mailingAddress2
          ? formikMailing.setFieldValue(
              "mailingAddress2",
              bagContent?.mailingAddress2
            )
          : formikMailing.setFieldValue("mailingAddress2", "");
        bagContent?.mailingCity
          ? formikMailing.setFieldValue("mailingCity", bagContent?.mailingCity)
          : formikMailing.setFieldValue("mailingCity", "");
        bagContent?.mailingState
          ? formikMailing.setFieldValue(
              "mailingState",
              bagContent?.mailingState
            )
          : formikMailing.setFieldValue("mailingState", "");
        bagContent?.mailingZip
          ? formikMailing.setFieldValue("mailingZip", bagContent?.mailingZip)
          : formikMailing.setFieldValue("mailingZip", "");
      }
    }
  }, [bagContent]);

  React.useEffect(() => {
    setError("");
  }, [formik.values]);

  return (
    <>
      <Grid
        container
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={
          Number(thisStep) === Number(ADDRESS_STEP) ? formik.handleSubmit : formikMailing.handleSubmit
        }
      >
        <Grid item xs={6} p={0.5}>
          <TextField
            disabled={(bagContent?.cardType === "PAYCARD" || bagContent?.cardType === "CORPORATE_EXPENSE") && !!onboardingEmployerAttributes?.PAYCARD_USE_DEFAULT_ADDRESS ? true : false}
            required
            size="small"
            label={
              Number(thisStep) === Number(ADDRESS_STEP) ? "Residential address" : "Mailing address"
            }
            id={Number(thisStep) === Number(ADDRESS_STEP) ? "address1" : "mailingAddress1"}
            type={"text"}
            value={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.values.address1
                : formikMailing.values.mailingAddress1
            }
            onChange={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.handleChange
                : formikMailing.handleChange
            }
            onBlur={
              Number(thisStep) === Number(ADDRESS_STEP) ? formik.handleBlur : formikMailing.handleBlur
            }
            helperText={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.touched.address1 && formik.errors.address1
                : formikMailing.touched.mailingAddress1 &&
                  formikMailing.errors.mailingAddress1
            }
            error={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.touched.address1 && Boolean(formik.errors.address1)
                : formikMailing.touched.mailingAddress1 &&
                  Boolean(formikMailing.errors.mailingAddress1)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={6} p={0.5}>
          <TextField
            disabled={(bagContent?.cardType === "PAYCARD" || bagContent?.cardType === "CORPORATE_EXPENSE") && !!onboardingEmployerAttributes?.PAYCARD_USE_DEFAULT_ADDRESS ? true : false}
            size="small"
            label="Apt, unit, suite, or floor #"
            id={Number(thisStep) === Number(ADDRESS_STEP) ? "address2" : "mailingAddress2"}
            type={"text"}
            value={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.values.address2
                : formikMailing.values.mailingAddress2
            }
            onChange={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.handleChange
                : formikMailing.handleChange
            }
            onBlur={
              Number(thisStep) === Number(ADDRESS_STEP) ? formik.handleBlur : formikMailing.handleBlur
            }
            helperText={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.touched.address2 && formik.errors.address2
                : formikMailing.touched.mailingAddress2 &&
                  formikMailing.errors.mailingAddress2
            }
            error={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.touched.address2 && Boolean(formik.errors.address2)
                : formikMailing.touched.mailingAddress2 &&
                  Boolean(formikMailing.errors.mailingAddress2)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={4} p={0.5}>
          <TextField
            disabled={(bagContent?.cardType === "PAYCARD" || bagContent?.cardType === "CORPORATE_EXPENSE") && !!onboardingEmployerAttributes?.PAYCARD_USE_DEFAULT_ADDRESS ? true : false}
            required
            size="small"
            label="City"
            id={Number(thisStep) === Number(ADDRESS_STEP) ? "city" : "mailingCity"}
            type={"text"}
            value={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.values.city
                : formikMailing.values.mailingCity
            }
            onChange={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.handleChange
                : formikMailing.handleChange
            }
            onBlur={
              Number(thisStep) === Number(ADDRESS_STEP) ? formik.handleBlur : formikMailing.handleBlur
            }
            helperText={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.touched.city && formik.errors.city
                : formikMailing.touched.mailingCity &&
                  formikMailing.errors.mailingCity
            }
            error={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.touched.city && Boolean(formik.errors.city)
                : formikMailing.touched.mailingCity &&
                  Boolean(formikMailing.errors.mailingCity)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={4} p={0.5}>
          <TextField
            disabled={(bagContent?.cardType === "PAYCARD" || bagContent?.cardType === "CORPORATE_EXPENSE") && !!onboardingEmployerAttributes?.PAYCARD_USE_DEFAULT_ADDRESS ? true : false}
            required
            size="small"
            label="State"
            id={Number(thisStep) === Number(ADDRESS_STEP) ? "state" : "mailingState"}
            type={"text"}
            value={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.values.state
                : formikMailing.values.mailingState
            }
            // onChange={currentStep === AddressStep ? formik.handleChange : formikMailing.handleChange}
            onChange={(event) => {
              const { value } = event.target;
              const upperCasedValue = value.toUpperCase().slice(0, 2);
              if (Number(thisStep) === Number(ADDRESS_STEP)) {
                formik.setFieldValue("state", upperCasedValue);
              } else {
                formikMailing.setFieldValue("mailingState", upperCasedValue);
              }
            }}
            onBlur={
              Number(thisStep) === Number(ADDRESS_STEP) ? formik.handleBlur : formikMailing.handleBlur
            }
            helperText={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.touched.state && formik.errors.state
                : formikMailing.touched.mailingState &&
                  formikMailing.errors.mailingState
            }
            error={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.touched.state && Boolean(formik.errors.state)
                : formikMailing.touched.mailingState &&
                  Boolean(formikMailing.errors.mailingState)
            }
            inputProps={{ maxLength: 2 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} p={0.5}>
          <TextField
            disabled={(bagContent?.cardType === "PAYCARD" || bagContent?.cardType === "CORPORATE_EXPENSE") && !!onboardingEmployerAttributes?.PAYCARD_USE_DEFAULT_ADDRESS ? true : false}
            required
            size="small"
            label="Zip code"
            id={Number(thisStep) === Number(ADDRESS_STEP) ? "zip" : "mailingZip"}
            type={"text"}
            value={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.values.zip
                : formikMailing.values.mailingZip
            }
            onChange={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.handleChange
                : formikMailing.handleChange
            }
            onBlur={
              Number(thisStep) === Number(ADDRESS_STEP) ? formik.handleBlur : formikMailing.handleBlur
            }
            helperText={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.touched.zip && formik.errors.zip
                : formikMailing.touched.mailingZip &&
                  formikMailing.errors.mailingZip
            }
            error={
              Number(thisStep) === Number(ADDRESS_STEP)
                ? formik.touched.zip && Boolean(formik.errors.zip)
                : formikMailing.touched.mailingZip &&
                  Boolean(formikMailing.errors.mailingZip)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} p={0.5}>
          <LoadingButton
            size="small"
            variant="contained"
            color="primary"
            type="submit"
            loading={processStep.isLoading}
          >
            {getLabel("onboarding." + thisStep + ".btnContinue", "Continue")}
          </LoadingButton>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography variant="caption" color={"red"}>{error}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}
