import { useQuery } from "@tanstack/react-query";
import HttpService from "./HttpService";

async function fetchCustomerDetails() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/customer/info`
  );
  return response;
}

const CustomerService = () => {
  const { isLoading, isError, data, error } = useQuery(
    ["customerInfo"],
    fetchCustomerDetails,
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
    }
  );

  const customerInfo = data?.data || null;

  return {
    customerInfo: customerInfo,
    isLoading,
    isError,
    error,
  };
};

export default CustomerService;
